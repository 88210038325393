import { registerLicense } from "@syncfusion/ej2-base";
import "@syncfusion/ej2-treegrid/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-treegrid/styles/material.css";
import "@syncfusion/ej2-treegrid/styles/material-dark.css";
import {
  Chart,
  LineSeries as LineSeriesChart,
  Category as CategoryChart,
  Tooltip as TooltipChart,
  Legend as LegendChart,
  DataLabel as DataLabelChart,
} from "@syncfusion/ej2-charts";

Chart.Inject(
  LineSeriesChart,
  CategoryChart,
  TooltipChart,
  LegendChart,
  DataLabelChart
);
class BurndownChart {
  constructor({
    wrapper,
    data,
    title = "Gráfico de Burndown",
    data_inicial,
    data2,
  }) {
    registerLicense(
      "ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5RdkBiXX1bcXxRQ2lZ"
    );

    try {
      if (!wrapper) {
        throw new Error(
          "É necessário fornecer um wrapper para inicializar o Gráfico."
        );
      }
      // test
      this.$wrapper = $(wrapper);

      let ProgressoInicial = 100;
      const serieIdeal = data.map((data) => {
        ProgressoInicial -= data.Esforco;
        return {
          Data: data.Data,
          Ideal: Math.abs(ProgressoInicial) < 1e-10 ? 0 : ProgressoInicial,
        };
      });

      let ProgressoInicialRealizado = 100;
      const serieRealizado = data2.map((data) => {
        ProgressoInicialRealizado -= data.Esforco;
        return {
          Data: data.Data,
          Realizado:
            Math.abs(ProgressoInicialRealizado) < 1e-10
              ? 0
              : ProgressoInicialRealizado,
        };
      });

      console.log("Serie Ideal", serieIdeal);
      console.log("Serie Realizado", serieRealizado);

      const bigdata = [...serieIdeal, ...serieRealizado];

      const sortedData = bigdata.sort((a, b) => {
        const [diaA, mesA, anoA] = a.Data.split("-");
        const [diaB, mesB, anoB] = b.Data.split("-");
        const dataA = new Date(`${anoA}-${mesA}-${diaA}`);
        const dataB = new Date(`${anoB}-${mesB}-${diaB}`);
        return dataA - dataB;
      });

      sortedData.unshift({ Data: data_inicial, Ideal: 100, Realizado: 100 });

      console.log("Sroted data", sortedData);
      // Função auxiliar para atualizar o valor
      function updateValue(current, previous, isLast) {
        // Se não houver valor atual ou se for 0 (e não for o último ponto), retorna o anterior
        if (current === undefined || (!isLast && current === 0)) {
          return previous;
        }
        // Se o atual for menor que o anterior, adota o atual
        if (current < previous) {
          return current;
        }
        // Caso contrário, mantém o valor anterior
        return previous;
      }

      // Supondo que sortedData já esteja ordenado e com o ponto inicial inserido:
      const consolidatedData = sortedData.reduce((acc, current, index, arr) => {
        const lastItem = acc[acc.length - 1] || { Ideal: 100, Realizado: 100 };
        const isLast = index === arr.length - 1; // Se for o último ponto, pode aceitar 0 se for realmente desejado

        acc.push({
          Data: current.Data,
          Ideal: updateValue(current.Ideal, lastItem.Ideal, isLast),
          Realizado: updateValue(current.Realizado, lastItem.Realizado, isLast),
        });
        return acc;
      }, []);

      console.log("consolidatedData", consolidatedData);

      const uniqueDatesData = consolidatedData.reduce((acc, current) => {
        // Verifica se já existe um objeto para a mesma data
        const existing = acc.find((item) => item.Data === current.Data);

        if (existing) {
          // Atualiza apenas se o objeto atual for mais próximo de zero
          const isCurrentCloserToZero =
            Math.abs(current.Ideal) + Math.abs(current.Realizado) <
            Math.abs(existing.Ideal) + Math.abs(existing.Realizado);

          if (isCurrentCloserToZero) {
            existing.Ideal = current.Ideal;
            existing.Realizado = current.Realizado;
          }
        } else {
          // Adiciona ao acumulador caso não exista
          acc.push({ ...current });
        }

        return acc;
      }, []);

      console.log("uniqueDatesData", uniqueDatesData);
      const processedData = consolidatedData.map((item, index, arr) => {
        const previous = arr[index - 1] || { Ideal: 100, Realizado: 100 }; // Elemento anterior ou vazio

        return {
          Data: item.Data,
          Ideal: item.Ideal !== undefined ? item.Ideal : previous.Ideal,
          Realizado:
            item.Realizado !== undefined ? item.Realizado : previous.Realizado,
        };
      });

      console.log("processedData", processedData);

      // Criando o gráfico de Burndown
      const lineGrid = new Chart({
        primaryXAxis: {
          title: "Data de Entrega",
          valueType: "Category",
          interval: 1,
          labelPlacement: "OnTicks",
          edgeLabelPlacement: "Shift",
        },
        primaryYAxis: {
          title: "Progresso (%)",
          minimum: 0,
          maximum: 100,
          interval: 20,
        },
        series: [
          {
            dataSource: uniqueDatesData,
            xName: "Data",
            yName: "Ideal",
            name: "Ideal",
            type: "Line",
            marker: { visible: true },
          },
          {
            dataSource: uniqueDatesData,
            xName: "Data",
            yName: "Realizado",
            name: "Realizado",
            type: "Line",
            marker: { visible: true },
          },
        ],
        title,
        tooltip: { enable: true },
        legendSettings: { visible: true },
      });

      // Renderizando o gráfico no elemento
      this.$wrapper[0].innerHTML = "";
      lineGrid.appendTo(this.$wrapper[0]);
    } catch (e) {
      console.error("Erro ao inicializar o Gráfico de Burndown:", e);
    }
  }
}

export default BurndownChart;
